import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';

import iconUrlAuto from './image/car.png';
import iconUrlMoto from './image/moto.png';
import iconUrlBike from './image/bike.png';
import iconUrlWalk from './image/human.png';
import iconPizza from './image/tt.png';
import iconPizzaDelivery from './image/tt_delivery.png';

const Map = ({ transportFilter, courierFilter, showHistory, showDeliveryPoints, isDarkMode }) => {
  const [couriersData, setCouriersData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [pizzaPoints, setPizzaPoints] = useState([]);
  const mapRef = useRef(null);
  const historyLayerRef = useRef(L.layerGroup());
  const courierMarkersRef = useRef(L.layerGroup());
  const pizzaClusterGroupRef = useRef(L.markerClusterGroup());
  const tileLayerRef = useRef(null);

  useEffect(() => {
    const fetchCouriersData = () => {
      fetch('https://haitatsu.pizzaday.ua/api/couriers')
        .then(response => response.json())
        .then(data => {
          const latestCourierData = Object.values(data.reduce((acc, item) => {
            if (!acc[item.Курьер] || new Date(acc[item.Курьер].Период) < new Date(item.Период)) {
              acc[item.Курьер] = item;
            }
            return acc;
          }, {}));
          setCouriersData(latestCourierData);
        })
        .catch(error => console.error('Error fetching couriers data:', error));
    };

    fetchCouriersData();
    const interval = setInterval(fetchCouriersData, 30000); // Обновление каждые 30 секунд
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetch('https://haitatsu.pizzaday.ua/api/pizzapoints')
      .then(response => response.json())
      .then(data => setPizzaPoints(data))
      .catch(error => console.error('Error fetching pizza points:', error));
  }, []);

  useEffect(() => {
    if (courierFilter === 'Все') {
      historyLayerRef.current.clearLayers(); // Убираем историю при выборе "Все"
    } else if (courierFilter !== 'Все' && showHistory) {
      fetchHistoryData(courierFilter);
    } else if (courierFilter !== 'Все') {
      setHistoryData([]); // Убираем историю, если выбрана фамилия, но не выбрана история
    }
  }, [courierFilter, showHistory]);

  const fetchHistoryData = async (courierName) => {
    try {
      const response = await fetch('https://haitatsu.pizzaday.ua/api/couriers');
      const data = await response.json();
      const filteredData = data.filter(item => item.Наименование === courierName);
      setHistoryData(filteredData);
    } catch (error) {
      console.error('Error fetching history data:', error);
    }
  };

  const transportIcons = {
    'Авто': iconUrlAuto,
    'Мото': iconUrlMoto,
    'Вело': iconUrlBike,
    'Пеший ': iconUrlWalk,
  };

  const determineMarkerColor = (period) => {
    const now = new Date();
    const timeDifference = (now - new Date(period)) / 1000 / 60;

    if (timeDifference <= 5) {
      return 'red';
    } else if (timeDifference <= 60) {
      return 'green';
    } else {
      return 'gray';
    }
  };

  const createColoredIcon = (transportType, color) => {
    return L.divIcon({
      html: `<div style="border: 2px solid ${color}; width: 34px; height: 34px; border-radius: 50%; display: flex; align-items: center; justify-content: center;">
               <img src="${transportIcons[transportType]}" style="width:30px; height:30px;" />
             </div>`,
      className: ''
    });
  };

  const createHistoryIcon = (timeDifference) => {
    let color;
    if (timeDifference <= 5) {
      color = 'red';
    } else if (timeDifference <= 60) {
      color = 'green';
    } else {
      const intensity = Math.min(Math.floor((timeDifference - 60) / 120), 190);
      color = `rgb(${intensity}, ${intensity}, ${intensity})`; // от темно-серого к светло-серому
    }

    return L.divIcon({
      html: `<div style="width: 10px; height: 10px; background-color: ${color}; border-radius: 50%;"></div>`,
      className: 'history-marker',
    });
  };

  const pizzaIcon = L.icon({
    iconUrl: iconPizza,
    iconSize: [30, 30],
  });

  const pizzaDeliveryIcon = L.icon({
    iconUrl: iconPizzaDelivery,
    iconSize: [30, 30],
  });

  const filteredCouriersData = couriersData.filter(item => {
    const transportMatch = transportFilter === 'Все' || item.ТранспортноеСердство === transportFilter;
    const courierMatch = courierFilter === 'Все' || item.Наименование === courierFilter;
    return transportMatch && courierMatch;
  });

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map('map').setView([48.3794, 31.1656], 6);
      const tileLayerUrl = isDarkMode 
        ? 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
        : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
      tileLayerRef.current = L.tileLayer(tileLayerUrl, {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      });
      tileLayerRef.current.addTo(mapRef.current);
      mapRef.current.addLayer(courierMarkersRef.current);
      mapRef.current.addLayer(historyLayerRef.current);
      mapRef.current.addLayer(pizzaClusterGroupRef.current);
    } else {
      const tileLayerUrl = isDarkMode 
        ? 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
        : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
      
      tileLayerRef.current.setUrl(tileLayerUrl);
    }

    const map = mapRef.current;

    // Очищаем только слои курьеров и историю, чтобы не перезагружать карту
    courierMarkersRef.current.clearLayers();
    if (!showHistory || courierFilter === 'Все') {
      historyLayerRef.current.clearLayers(); // Очищаем историю, если не выбрана опция истории или выбран фильтр "Все"
    }

    // Добавление маркеров курьеров
    filteredCouriersData.forEach((courier) => {
      L.marker([courier.Широта, courier.Долгота], {
        icon: createColoredIcon(courier.ТранспортноеСердство, determineMarkerColor(courier.Период)),
      }).bindPopup(`${courier.Наименование} <br /> ${courier.ТранспортноеСердство} <br /> ${courier.Период}`).addTo(courierMarkersRef.current);
    });

    if (showHistory && courierFilter !== 'Все' && historyData.length > 0) {
      historyLayerRef.current.clearLayers(); // Убираем предыдущие слои истории перед добавлением новых
      const sortedHistoryData = historyData.sort((a, b) => new Date(a.Период) - new Date(b.Период));
      const latlngs = sortedHistoryData.map(location => [location.Широта, location.Долгота]);

      const polyline = L.polyline(latlngs, { color: 'lightblue', dashArray: '5, 10' });
      polyline.addTo(historyLayerRef.current);

      sortedHistoryData.forEach((location, index) => {
        const isLastPoint = index === sortedHistoryData.length - 1;
        const timeDifference = (new Date() - new Date(location.Период)) / 1000 / 60;
        const icon = isLastPoint ? createColoredIcon(location.ТранспортноеСердство, determineMarkerColor(location.Период)) : createHistoryIcon(timeDifference);

        L.marker([location.Широта, location.Долгота], {
          icon: icon,
        }).bindPopup(`${location.Наименование} <br /> ${location.ТранспортноеСердство} <br /> ${location.Период}`).addTo(historyLayerRef.current);
      });
    }

    // Добавление точек с пиццериями с условием отображения иконки в зависимости от deliveryFlagDefault
    pizzaClusterGroupRef.current.clearLayers();

    pizzaPoints.forEach((point) => {
      const latitude = parseFloat(point.geolat.replace(',', '.'));
      const longitude = parseFloat(point.geolon.replace(',', '.'));
      const icon = point.deliveryFlagDefault ? pizzaDeliveryIcon : pizzaIcon;

      if (!showDeliveryPoints || (showDeliveryPoints && point.deliveryFlagDefault)) {
        pizzaClusterGroupRef.current.addLayer(L.marker([latitude, longitude], {
          icon: icon,
        }).bindPopup(point.about));
      }
    });

    map.addLayer(pizzaClusterGroupRef.current);

  }, [filteredCouriersData, historyData, pizzaPoints, showHistory, showDeliveryPoints, courierFilter, isDarkMode]);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <div id="map" style={{ height: "100%", width: "100%" }}></div>
    </div>
  );
};

export default Map;

