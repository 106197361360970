import React, { useState, useEffect } from 'react';
import Map from './Map';
import LoginForm from './LoginForm';
import SettingsPanel from './SettingsPanel';
import ChangelogPopup from './ChangelogPopup';  // Import the ChangelogPopup component
import './App.css';
import sunIcon from './image/sun.png';
import moonIcon from './image/moon.png';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [transportFilter, setTransportFilter] = useState('Все');
  const [courierFilter, setCourierFilter] = useState('Все');
  const [showLegend, setShowLegend] = useState(false);
  const [transports, setTransports] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [showDeliveryPoints, setShowDeliveryPoints] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showChangelog, setShowChangelog] = useState(false);

  useEffect(() => {
    const savedUser = JSON.parse(localStorage.getItem('currentUser'));
    const changelogSeen = localStorage.getItem('changelogSeen');
    
    if (savedUser) {
      setCurrentUser(savedUser);
      setIsAuthenticated(true);
      setIsAdmin(savedUser.role === 'admin');
      resetLogoutTimer();
      
      if (!changelogSeen) {
        setShowChangelog(true);  // Show changelog if not seen before
      }
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      resetLogoutTimer();
    }
  }, [isAuthenticated]);

  const resetLogoutTimer = () => {
    clearTimeout(window.logoutTimer);
    window.logoutTimer = setTimeout(handleLogout, 60 * 60 * 1000); // Logout after 60 minutes
  };

  const handleLogin = (username, password) => {
    fetch('https://haitatsu.pizzaday.ua/api/users.json')
      .then(response => response.json())
      .then(users => {
        const user = users.find(u => u.username === username && u.password === password);
        if (user) {
          setCurrentUser(user);
          setIsAuthenticated(true);
          setIsAdmin(user.role === 'admin');
          localStorage.setItem('currentUser', JSON.stringify(user));
          resetLogoutTimer();

          const changelogSeen = localStorage.getItem('changelogSeen');
          if (!changelogSeen) {
            setShowChangelog(true);  // Show changelog after login if not seen before
          }
        } else {
          alert('Неверный логин или пароль');
        }
      })
      .catch(error => console.error('Error during login:', error));
  };

  const handleLogout = () => {
    setCurrentUser(null);
    setIsAuthenticated(false);
    setIsAdmin(false);
    localStorage.removeItem('currentUser');
    clearTimeout(window.logoutTimer);
  };

  const closeChangelog = () => {
    setShowChangelog(false);
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    fetch('https://haitatsu.pizzaday.ua/api/couriers')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const uniqueTransports = [...new Set(data.map(item => item.ТранспортноеСердство))];
        const uniqueCouriers = [...new Set(data.map(item => item.Наименование))];
        setTransports(['Все', ...uniqueTransports]);
        setCouriers(['Все', ...uniqueCouriers]);
      })
      .catch(error => console.error('Error fetching transports or couriers:', error));
  }, []);

  const toggleLegend = () => {
    setShowLegend(!showLegend);
  };

  const toggleDeliveryPoints = () => {
    setShowDeliveryPoints(!showDeliveryPoints);
  };

  const handleHistoryClick = () => {
    if (courierFilter !== 'Все') {
      setShowHistory(true);
    } else {
      setShowHistory(false);
    }
  };

  return (
    <div className="App">
      {!isAuthenticated ? (
        <div className="login-container">
          <h1 className="app-title">Curier Map 1.1</h1>
          <video className="background-video" autoPlay loop muted>
            <source src="./dragon.mp4" type="video/mp4" />
          </video>
          <div className="login-content">
            <LoginForm onLogin={handleLogin} />
          </div>
        </div>
      ) : (
        <>
          {showChangelog && <ChangelogPopup onClose={closeChangelog} />}
          <div id="filterPanel">
            <h2>Фильтры</h2>
            <label>Транспорт:</label>
            <select value={transportFilter} onChange={(e) => setTransportFilter(e.target.value)}>
              {transports.map((transport, index) => (
                <option key={index} value={transport}>{transport}</option>
              ))}
            </select>
            <label>Курьер:</label>
            <select value={courierFilter} onChange={(e) => setCourierFilter(e.target.value)}>
              {couriers.map((courier, index) => (
                <option key={index} value={courier}>{courier}</option>
              ))}
            </select>
            <button id="historyButton" onClick={handleHistoryClick}>История перемещений</button>
            <button id="legendButton" onClick={toggleLegend}>Легенда</button>
            <button id="deliveryButton" onClick={toggleDeliveryPoints}>
              {showDeliveryPoints ? 'ТТ без доставки' : 'ТТ с доставкой'}
            </button>
            <div id="themeToggleButton" onClick={toggleTheme}>
              <img src={isDarkMode ? moonIcon : sunIcon} alt="Theme Toggle" />
            </div>
            {showLegend && (
              <div id="legend">
                <h3>Легенда</h3>
                <ul>
                  <li><img src="./image/human.png" alt="Icon 1" /> Пеший Курьер</li>
                  <li><img src="./image/moto.png" alt="Icon 2" /> Курьер на мопеде</li>
                  <li><img src="./image/car.png" alt="Icon 3" /> Курьер на Авто</li>
                  <li><img src="./image/bike.png" alt="Icon 4" /> Курьер на Велосипеде</li>
                  <li><img src="./image/tt.png" alt="Icon 5" /> Локация Точки</li>
                  <li><img src="./image/tt_delivery.png" alt="Icon 6" /> Локация Точки с доставкой</li>
                </ul>
              </div>
            )}
            {showSettings && <SettingsPanel currentUser={currentUser} isAdmin={isAdmin} />}
            <div className="user-panel">
              <button onClick={toggleSettings}>Настройки</button>
              <button onClick={handleLogout}>Выход</button>
            </div>
          </div>
          <Map 
            transportFilter={transportFilter} 
            courierFilter={courierFilter} 
            showHistory={showHistory} 
            showDeliveryPoints={showDeliveryPoints} 
            isDarkMode={isDarkMode}
          />
        </>
      )}
    </div>
  );
}

export default App;

