import React, { useState } from 'react';
import './App.css';

function LoginForm({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(username, password);
  };

  return (
    <div className="login-container">
      <video autoPlay loop muted className="background-video">
        <source src="./image/dragon.mp4" type="video/mp4" />
        Ваш браузер не поддерживает видео.
      </video>
      <div className="login-overlay">
        <h1 className="app-title">Curier Map 1.1</h1>
        <form onSubmit={handleSubmit} className="login-form">
          <h2>Вход в систему</h2>
          <input type="text" placeholder="Логин" value={username} onChange={(e) => setUsername(e.target.value)} required />
          <input type="password" placeholder="Пароль" value={password} onChange={(e) => setPassword(e.target.value)} required />
          <button type="submit">Войти</button>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;

