import React, { useState } from 'react';
import './ChangelogPopup.css';

const ChangelogPopup = ({ onClose }) => {
  const [acknowledged, setAcknowledged] = useState(false);

  const handleCheckboxChange = (e) => {
    setAcknowledged(e.target.checked);
  };

  const handleClose = () => {
    if (acknowledged) {
      localStorage.setItem('changelogSeen', 'true');
      onClose();
    }
  };

  return (
    <div className="changelog-popup">
      <h2>Последние изменения</h2>
      <ul>
        <li>1: Исправлено автообновление местоположения курьера, перезагружать страницу более не требуется</li>
        <li>2: Добавлена темная тема, можно переключить в правом верхнем углу</li>
        <li>3: Карта больше не мерцает (могло быть у ряда пользователей)</li>
        <li>4: Оптимизирована загрузка данных</li>
	<li>5: Сессия активна 60 минут, при бездействии произойдет выход из учетной записи</li>
	<li>6: Изменился вариант отображения истории перемещения курьера на более простой</li>
      </ul>
      <div className="acknowledge-section">
        <input 
          type="checkbox" 
          id="acknowledge" 
          checked={acknowledged} 
          onChange={handleCheckboxChange} 
        />
        <label htmlFor="acknowledge">Я ознакомлен</label>
      </div>
      <button onClick={handleClose} disabled={!acknowledged}>Закрыть</button>
    </div>
  );
};

export default ChangelogPopup;

