import React, { useState, useEffect } from 'react';

function SettingsPanel({ currentUser, isAdmin }) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (isAdmin) {
      fetch('/api/users.json')
        .then(response => response.json())
        .then(data => setUsers(data));
    }
  }, [isAdmin]);

  return (
    <div>
      <h2>Настройки пользователя</h2>
      <p>Логин: {currentUser.username}</p>
      <p>Роль: {currentUser.role}</p>
      {isAdmin && (
        <>
          <h3>Управление пользователями</h3>
          <ul>
            {users.map(user => (
              <li key={user.username}>{user.username} ({user.role})</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default SettingsPanel;

